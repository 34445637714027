<template>
	<div
		class="max-w-md p-4 constrained relative overflow-hidden bg-white md:bg-transparent"
	>
		<progress
			class="mb-6 green-500"
			:max="stepCount"
			:value="currentStepIndex + 1"
		/>
		<div v-if="currentStep && currentStep.title" class="flex justify-between">
			<div>
				<button
					v-if="previousStep"
					:disabled="previousButtonDisabled"
					:class="{
						'text-gray-500': previousButtonDisabled,
					}"
					@click="goBack()"
				>
					<svg-icon name="chevron-left" class="w-4 h-4 fill-current" />
				</button>
			</div>
			<transition-group tag="span" :name="transitionName">
				<div :key="currentStep.route">
					<span class="uppercase font-bold">{{ currentStep.title }}</span>
				</div>
			</transition-group>
			<div>
				<button
					v-if="nextStep"
					:disabled="nextButtonDisabled"
					:class="{ 'text-gray-500': nextButtonDisabled }"
					@click="goNext()"
				>
					<svg-icon name="chevron-right" class="w-4 h-4 fill-current" />
				</button>
			</div>
		</div>
		<hr class="my-6" />
		<transition-group tag="span" :name="transitionName">
			<div
				:key="currentStep.route"
				class="md:bg-white md:p-10 filter drop-shadow-md"
			>
				<NuxtPage
					:contact-info="data"
					:is-loading-info="isFetching"
					:loading-info-fetch-error="fetchError"
					@update="onUpdate"
					@load-contact-info="loadContactInfoHandler"
					@go-next="goNext()"
				></NuxtPage>
			</div>
		</transition-group>
	</div>
</template>

<script lang="ts">
import {type GetContactInformationOutput, UserRegistrationApi} from '~/gen/openapi/portalService';
import {useBankIDStore} from "~/stores/bankID";

const TRANSITION_FORWARD = 'transition--slide-forward'
const TRANSITION_BACK = 'transition--slide-back'

function getLastSegment(path: string): string {
	const pathParts = path.split('/')
	return pathParts[pathParts.length - 1]
}

definePageMeta({
  layout: 'reset',
  middleware: defineNuxtRouteMiddleware((to) => {
    if (to.name !== 'register-simple-contactId-login' && !useBankIDStore().isLoggedIn) {
      return navigateTo({
        ...to,
        name: 'register-simple-contactId-login',
        replace: true,
      })
    }
  }),
})
export default defineComponent({
	setup() {
		const data = ref<GetContactInformationOutput>()
		const transitionName = ref(TRANSITION_FORWARD)
		const route = useRoute()
		const router = useRouter()
		const { t } = useTranslation()
		const { $config, $axios } = useNuxtApp()
    const bankIDStore = useBankIDStore()

		const registrationApi = new UserRegistrationApi(
			undefined,
			$config.public.apiBaseHost,
			$axios
		)
		const fetchError = ref<string>()
		const isFetching = ref<Boolean>(false)

		const steps = [
			{
				route: 'login',
				title: t('REGISTER'),
				navigation: {
					nextDisabled: true,
					previousDisabled: true,
				},
			},
			{
				route: 'confirm-access',
				title: t('LOADING_INFO'),
				navigation: {
					nextDisabled: true,
					previousDisabled: true,
				},
			},
			{
				route: 'contact-info',
				title: t('CONTACT_INFORMATION'),
				navigation: {
					nextDisabled: true,
					previousDisabled: false,
				},
			},
			{
				route: 'summary',
				title: t('SUMMARY'),

				navigation: {
					nextDisabled: true,
					previousDisabled: false,
				},
			},
			{
				route: 'completing',
				title: t('FINISHING'),
				navigation: {
					nextDisabled: true,
					previousDisabled: true,
				},
			},
			{
				route: 'success',
				title: t('FINISHED'),
				navigation: {
					nextDisabled: true,
					previousDisabled: true,
				},
			},
		]

		function findStepIndex(route: string) {
			return steps.findIndex((step) => step.route === route)
		}

		const lastSegment = computed(() => {
			return getLastSegment(route.path)
		})

		const currentStepIndex = computed(() => findStepIndex(lastSegment.value))

		const currentStep = computed(() => steps[currentStepIndex.value])

		const stepCount = steps.length

		const nextStep = computed(() => {
			return steps[currentStepIndex.value + 1]
		})
		const previousStep = computed(() => {
			return steps[currentStepIndex.value - 1]
		})

		const nextButtonDisabled = computed(() => {
			return (
				currentStep.value.navigation &&
				currentStep.value.navigation.nextDisabled
			)
		})
		const previousButtonDisabled = computed(() => {
			return (
				currentStep.value.navigation &&
				currentStep.value.navigation.previousDisabled
			)
		})

		// Update shared data-value
		const onUpdate = (value: any) => {
			data.value = value
		}

		// Set the transition direction based on step order
		router.beforeEach((to, from, next) => {
			const toStep = findStepIndex(getLastSegment(to.path))
			const fromStep = findStepIndex(getLastSegment(from.path))

			if (toStep < fromStep) {
				transitionName.value = TRANSITION_BACK
			} else {
				transitionName.value = TRANSITION_FORWARD
			}
			next()
		})

		const goNext = () => {
			if (nextStep.value?.route) {
				router.push(`./${nextStep.value.route}`)
			}
		}

		const goBack = () => {
			if (previousStep.value?.route) {
				router.push(`./${previousStep.value.route}`)
			}
		}

		const fetchContactInformation = async () => {
			isFetching.value = true
			fetchError.value = undefined
      const tokens = await bankIDStore.getTokensRefreshIfNeeded()
			if (tokens) {
				try {
					const contactInformation = await registrationApi
						.userRegistrationGetContactInformation({
							ContactIdCrm: route.params.contactId as string,
							AccessToken: tokens.access_token,
							IdToken: tokens.id_token,
						})
					data.value = contactInformation.data
				} catch (e: any) {
					if (e.response.data === 'BankID verification failed') {
						fetchError.value = 'BANKID_VERIFICATION_FAILED'
					} else {
						fetchError.value = 'ERROR_COULD_NOT_LOAD_PAGE'
					}
				} finally {
					isFetching.value = false
				}
			} else {
				// login again
				await router.push(`./login`)
			}
		}

		const loadContactInfoHandler = () => {
			if (data.value) {
				// assume we have already fetched the contact info
				return
			}
			fetchContactInformation()
		}

		return {
			data,
			onUpdate,
			stepCount,
			currentStep,
			currentStepIndex,
			nextStep,
			previousStep,
			goNext,
			goBack,
			transitionName,
			nextButtonDisabled,
			previousButtonDisabled,
			isFetching,
			fetchError,
			loadContactInfoHandler,
      bankIDStore,
		}
	},
})
</script>
