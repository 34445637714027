<template>
  <div class="max-w-md p-4 constrained relative overflow-hidden bg-white md:bg-transparent" v-if="$config.public.features.newRegistration">
    <WizardNavigation
        :current-step="currentStepIndex + 1"
        :has-next-step="!!nextStep"
        :has-previous-step="!!previousStep"
        :hide-navigation="!currentStep || !currentStep.title"
        :is-next-enabled="!nextButtonDisabled"
        :is-previous-enabled="!previousButtonDisabled"
        :step-count="stepCount"
        :title="currentStep ? currentStep.title || '' : ''"
        @on-back-click="goBack()"
        @on-next-click="goNext()"
    />
    <hr class="my-6"/>

    <transition name="transition--fade" mode="out-in">
      <div
          v-if="currentStep"
          :key="currentStep.route"
          class="md:bg-white md:p-10 filter drop-shadow-md"
      >
        <NuxtPage
            :current-step-index="currentStepIndex"
            :has-next-step="!!nextStep"
            :has-previous-step="!!previousStep"
            :step-count="stepCount"
            :title="currentStep.title"
            @go-next="goNext()"
        ></NuxtPage>
        <Button
            type="button"
            class="w-full mt-4 justify-center"
            weight="tertiary"
            @click.stop.prevent="onCancelClick">Avbryt registrering
        </Button>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import WizardNavigation from '~/components/registration/WizardNavigation.vue';
import {useBankIDStore} from "~/stores/bankID";
import Button from "~/components/buttons/Button.vue";

function getLastSegment(path: string): string {
  const pathParts = path.split('/')
  return pathParts[pathParts.length - 1]
}

definePageMeta({
  layout: 'basic-public',
  middleware: defineNuxtRouteMiddleware((to) => {
    if (to.name !== 'register-new-forest-owner-login' && !useBankIDStore().isLoggedIn) {
      return navigateTo({
        ...to,
        name: 'register-new-forest-owner-login',
        replace: true,
      })
    }
  })
})

type Step = {
  route: string
  title: string | null
  navigation: {
    nextDisabled: boolean
    previousDisabled: boolean
  }
  next?: () => string
  previous?: () => string
}
export default defineComponent({
  components: {
    Button,
    WizardNavigation
  },
  setup() {
    const bankIDStore = useBankIDStore()
    const route = useRoute()
    const router = useRouter()
    const {t} = useTranslation()
    const { $config } = useNuxtApp()

    const hasSelectedShareHolder = computed(() => route.query.shareHolder == '1')

    // Steps in order of appearance
    const steps: Step[] = !$config.public.features.newRegistration ? [] : [
      {route: 'login', title: t('REGISTER'), navigation: {nextDisabled: true, previousDisabled: true}},
      {route: 'company', title: t('COMPANY'), navigation: {nextDisabled: true, previousDisabled: true}},
      {route: 'confirm-access', title: t('LOADING_INFO'), navigation: {nextDisabled: true, previousDisabled: false}},
      {
        route: 'about-you',
        title: t('LOADING_INFO'),
        navigation: {nextDisabled: true, previousDisabled: false},
        next: () => {
          if (route.query.propertiesForTransfer?.length) {
            return 'transfer'
          } else {
            return 'contact-info'
          }
        }
      },
      {route: 'transfer', title: 'Overdragelse', navigation: {nextDisabled: true, previousDisabled: false}},
      {
        route: 'contact-info',
        title: t('CONTACT_INFORMATION'),
        navigation: {nextDisabled: true, previousDisabled: false},
        previous: () => {
          if (route.query.propertiesForTransfer?.length) {
            return 'transfer'
          } else {
            return 'about-you'
          }
        }
      },
      {
        route: 'bank-account',
        title: t('BANK_ACCOUNT_NUMBER'),
        navigation: {nextDisabled: true, previousDisabled: false}
      },
      {route: 'share-holder', title: t('SHAREHOLDER'), navigation: {nextDisabled: false, previousDisabled: false}},
      {
        route: 'skog-subscription',
        title: t('SUBSCRIBE_TO_SKOG'),
        navigation: {nextDisabled: false, previousDisabled: false}
      },
      {route: 'allma', title: t('DIGITAL_FOREST_PLAN'), navigation: {nextDisabled: false, previousDisabled: false}},
      {route: 'summary', title: t('SUMMARY'), navigation: {nextDisabled: false, previousDisabled: false}},
      {route: 'done', title: null, navigation: {nextDisabled: false, previousDisabled: false}},
    ]

    function findStepIndex(route: string) {
      return steps.findIndex((step) => step.route === route)
    }

    const lastSegment = computed(() => {
      return getLastSegment(route.path)
    })

    const currentStepIndex = computed(() => findStepIndex(lastSegment.value))

    const currentStep = computed(() => steps[currentStepIndex.value])

    const stepCount = steps.length

    const nextStep = computed(() => {
      return steps[currentStepIndex.value + 1]
    })

    const previousStep = computed(() => {
      return steps[currentStepIndex.value - 1]
    })

    const nextButtonDisabled = computed(() => {
      return (
          currentStep.value.navigation &&
          currentStep.value.navigation.nextDisabled
      )
    })

    const previousButtonDisabled = computed(() => {
      return (
          currentStep.value.navigation &&
          currentStep.value.navigation.previousDisabled
      )
    })

    const goNext = () => {
      if (route.query.error?.length) route.query.error = ''
      if (currentStep.value.next) {
        router.push({path: `./${currentStep.value.next()}`, query: route.query})
      } else if (nextStep.value?.route) {
        // Go straight to summary if user has not selected "Yes" to be a share holder
        if (!hasSelectedShareHolder.value && ['skog-subscription'].includes(nextStep.value.route)) {
          router.push({path: `./allma`, query: route.query})
        } else {
          router.push({path: `./${nextStep.value.route}`, query: route.query})
        }
      }
    }

    const goBack = () => {
      if (route.query.error?.length) route.query.error = ''
      if (currentStep.value.previous) {
        router.push({path: `./${currentStep.value.previous()}`, query: route.query})
      } else if (previousStep.value?.route) {
        // Skip over invalid pages if selected to not be a share holder
        if (!hasSelectedShareHolder.value && ['skog-subscription'].includes(previousStep.value.route)) {
          router.push({path: `./share-holder`, query: route.query})
        } else {
          router.push({path: `./${previousStep.value.route}`, query: route.query})
        }
      }
    }

    const onCancelClick = () => {
      const bankIDStore = useBankIDStore()
      bankIDStore.logout()
      window.location.href = '/';
    }

    onMounted(async () => {
      if (!$config.public.features.newRegistration) {
        console.warn('User registration is disabled')
        window.location.href = '/';
      }
    })

    return {
      bankIDStore,
      goNext,
      goBack,
      currentStep,
      currentStepIndex,
      stepCount,
      nextStep,
      previousStep,
      nextButtonDisabled,
      previousButtonDisabled,
      onCancelClick,
    }
  }
})
</script>


