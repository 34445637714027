<template>
	<div class="w-full">
		<main class="constrained">
			<InstructionsV2 />
		</main>
	</div>
</template>
<script lang="ts">
import InstructionsV2 from '~/components/blocks/contractor/InstructionsV2.vue';

definePageMeta({
  layout: 'basic-public',
})
export default defineComponent({
	components: {
		InstructionsV2,
	},
  setup() {
    const { t } = useTranslation()

    return {
      t
    }
  },
	head () {
		return {
			title: `${ this.t('INSTRUCTIONS') } - ${this.t('CONTRACTOR_PORTAL')} - Glommen Mjøsen`,
		}
	},
})
</script>
