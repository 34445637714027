<template>
	<div class="w-full flex flex-col items-center h-full">
		<OrderForestManagementPlanWizard
			:project-no="projectNo"
		/>
	</div>
</template>
<script lang="ts">
import OrderForestManagementPlanWizard from '@/components/pages/order-forest-management-plan/index.vue'

definePageMeta({
  layout: 'basic-public',
})
export default defineComponent({
	components: {
		OrderForestManagementPlanWizard,
	},
  setup() {
    const route = useRoute()

    return {
      route
    }
  },
	computed: {
		projectNo() {
			const id = this.route.query.projectNo
			if (typeof id === 'string') {
				return id
			}
			if (Array.isArray(id)) {
				return id.flat()[0]
			}
			return null
		}
	}
})
</script>
