import { computed } from 'vue';

/**
 * Workaround for https://github.com/intlify/vue-i18n-next/issues/1155
 *
 * In newer versions of vue-i18n, $t and $te are becoming legacy.
 *
 * Instead of using built-in useI18n() in our templates,
 * use this composable that handles undefined/null keys.
 */

export const useTranslation = () => {
    const i18n = useI18n();

    // Null-safe `t` function
    const nullSafeT = (key?: string | null, ...args: any[]): string => {
        // If the key is null, undefined, or empty, return an empty string immediately
        if (key == null || key.trim() === '') {
            return '';
        }

        // @ts-expect-error
        return computed(() => i18n.t(key, ...args)).value;
    };

    // Null-safe `te` function
    const nullSafeTe = (key?: string | null, ...args: any[]): boolean => {
        if (key == null || key.trim() === '') {
            return false; // Return false for invalid keys
        }

        return computed(() => i18n.te(key, ...args)).value;
    };

    return {
        ...i18n,
        t: nullSafeT,
        te: nullSafeTe,
    };
};
