import {defineStore, getActivePinia} from 'pinia'
import { useLocalStorage } from '@vueuse/core'
import {getAxiosConfig} from '~/store/constants/api-url'
import {type PermissionModel, UserApi} from '~/gen/openapi/portalService'
import { CUSTOMER_ROLES } from '~/store/constants/customer'
import { useAccountStore } from '~/stores/account'
import { userConfirmedAccountData } from '~/api/api'
import {useLocaleStore} from '~/stores/locale';
import {useAccountService} from "~/services/account";

interface State {
	changePwErrorMsg: string
	isChangingPw: boolean
	isLoggedIn: boolean
	isLoggedInTime: number
	isLoggingIn: boolean
	isChanged: boolean
	isCheckingLoginStatus: boolean
	isReset: boolean
	isResetingPassword: boolean
	loginErrorMsg: string
	newPasswordErrorMsg: string
	resetErrorMsg: string
	userData: any
	currentContactId: string | null
	hasDismissedBecomeShareholderBanner: boolean
}

let checkLoginPromise: Promise<any> | undefined

export const useUserStore = defineStore('user', {
	state: (): State => ({
		changePwErrorMsg: '',
		isChangingPw: false, // typescript-issues for now, let's see if it magically goes away after nuxt3 upgrade
		// @ts-ignore
		isLoggedIn: useLocalStorage('pinia/user/isLoggedIn', false),
		// @ts-ignore
		isLoggedInTime: useLocalStorage('pinia/user/isLoggedInTime', 0),
		isLoggingIn: false,
		isChanged: false,
		isCheckingLoginStatus: false,
		isReset: false,
		isResetingPassword: false,
		loginErrorMsg: '',
		newPasswordErrorMsg: '',
		resetErrorMsg: '',
		// @ts-ignore
		userData: useLocalStorage(
			'pinia/user/userData',
			{},
			{
				mergeDefaults: true,
			}
		),
		/**
		 * current logged-in users contact id (CRM ID). Different from userData id (EPI-server).
		 * @type {string | null}
		 * */
		// @ts-ignore
		currentContactId: useLocalStorage('pinia/user/currentContactId', null),
		// @ts-ignore
		hasDismissedBecomeShareholderBanner: useLocalStorage('pinia/user/hasDismissedBecomeShareholderBanner', false),
	}),
	getters: {
		name: (state) => {
			if (!state.userData) {
				return 'Loading'
			}
			const user = state.userData

			if (!user.firstName && !user.lastName) {
				return user.email
			}

			return `${user.firstName || ''} ${user.lastName || ''}`.trim()
		},
		userId: (state) => {
			if (!state.userData) {
				return null
			}
			return state.userData.userid
		},
	},
	actions: {
		changePassword(data: any) {
			if (
				!data ||
				!('oldPassword' in data) ||
				!data.oldPassword ||
				!('newPassword' in data) ||
				!data.newPassword
			) {
				return
			}

			this.changePwErrorMsg = ''
			this.isChangingPw = true

			const { $axios, $config } = useNuxtApp()

			return new Promise((resolve, reject) => {
				$axios
					.post(
						`${$config.public.sessionApiBaseUrl}/sessions/changepassword`,
						{
							username: this.userData.userid,
							oldPassword: data.oldPassword,
							newPassword: data.newPassword,
						},
						getAxiosConfig()
					)
					.then((response) => {
						this.isChangingPw = false
						resolve(response)
					})
					.catch((error) => {
						this.changePwErrorMsg = 'ERROR_CHANGE_PW'
						this.isChangingPw = false
						reject(error)
					})
			})
		},
		checkLogin(force: boolean) {
			if (checkLoginPromise) {
				return checkLoginPromise
			}
			const { $axios } = useNuxtApp()
			const millisecondsSinceLogin = Date.now() - (this.isLoggedInTime || 0)
			const millisecondsLoginReCheck = 90000 // 1.5m * 60s * 1000ms

			checkLoginPromise = new Promise((resolve, reject) => {
				if (!force && millisecondsSinceLogin < millisecondsLoginReCheck) {
					resolve(true)
					checkLoginPromise = undefined
				} else if (!this.isCheckingLoginStatus) {
					this.isCheckingLoginStatus = true
					$axios
						.get(`/auth/refresh`, {
							withCredentials: true,
							// @ts-ignore
							// must be "null", undefined is not enough
							baseURL: null
						})
						.then((response) => {
							if (response.status < 300) {
								this.isLoggedIn = true
								this.isLoggedInTime = Date.now()
								this.isCheckingLoginStatus = false
								this.isLoggingIn = false

								if (
									response.status === 200 &&
									response.data &&
									typeof response.data === 'object'
								) {
									this.userData = response.data
									this.loadCurrentContactId()
								}

								resolve(response)
							} else {
								this.isLoggedIn = false
								reject(response)
							}
						})
						.catch((error) => {
							reject(error)
						})
						.finally(() => {
							this.isCheckingLoginStatus = false
							this.isLoggingIn = false
							checkLoginPromise = undefined
						})
				}
			})

			return checkLoginPromise
		},

		login({ impersonate, loginContext }: {impersonate?: boolean, loginContext?: String} = {}) {
            const localeStore = useLocaleStore()
			this.loginErrorMsg = ''
            window.location.href = `/auth/login?ui_locales=${localeStore.locale || 'no'}${impersonate ? '&impersonate=1' : ''}${loginContext ? '&loginContext=' + loginContext : ''}`
		},
		setLoginErrorMsg(msg: string) {
			this.loginErrorMsg = msg;
		},
		loginCallback({callbackUrl, code, loginContext, $router}: any, accounts: Ref<PermissionModel[]> | Ref<undefined>) {
			this.loginErrorMsg = ''
			this.isLoggingIn = true
			const { $axios } = useNuxtApp()
			const currentAccountStore = useAccountStore()

			return new Promise((resolve, reject) => {
				$axios
					.$get(`${callbackUrl}?code=${code}`,{
							// @ts-ignore
							baseURL: null
						}
					)
					.then((response) => {
						const activeAccount = currentAccountStore.activeAccount
						this.userData = response
						this.isLoggedIn = true
						this.isLoggedInTime = Date.now()
						this.loadCurrentContactId()

						// If there is an active account we redirect to the last used account on login
						if (activeAccount && accounts.value?.length) {
							const accountData = accounts.value?.find(
								(account) => account.AccountId === activeAccount
							)
							if (accountData && accountData.Role) {
								const role = CUSTOMER_ROLES.find(
									(role) => role.id === accountData.Role
								)

								if (role && role.path && $router) {
									$router.push(`/${role.path}/${activeAccount}`)
								}
							}
						}
						resolve(response)
					})
					.catch((error) => {
						if (error.response && error.response.status === 401) {
							this.loginErrorMsg = 'ERROR_LOGIN_INVALID_CREDENTIALS'
						} else {
							this.loginErrorMsg = 'ERROR_LOGIN_TIMEOUT'
						}
						reject(error)
					})
					.then(() => {
                        this.isLoggingIn = false
                        if (loginContext === 'forestManagementPlan') {

                            const project = localStorage.getItem('orderForestManagementPlanLatestProject')
                            window.location.href = `/bestill-skogbruksplan?projectNo=${project}`
                        } else {
                            window.location.href = '/'
                        }
					})
			})
		},

		trigLogout(queries?: Array<{ [key: string]: string }>) {
			let uri = '/auth/logout'

			if (queries && queries.length > 0) {
				const queryParams = queries.map(query => {
					return Object.entries(query)
						.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
						.join('&')
				}).join('&')

				uri += '?' + queryParams
			}

			window.location.href = uri
		},

		resetAllStores() {
			const storesToPreserve = ['locale']
			const activePinia = getActivePinia();
			if (activePinia) {
				Object.entries(activePinia.state.value).filter(([storeName]) => !storesToPreserve.includes(storeName)).forEach(([storeName, state]) => {
					const storeDefinition = defineStore(storeName, state);
					const store = storeDefinition(activePinia);
					store.$reset();
				})
			}
		},
		reset() {
			localStorage.removeItem('pinia/user/isLoggedIn')
			localStorage.removeItem('pinia/user/isLoggedInTime')
			localStorage.removeItem('pinia/user/userData')
			localStorage.removeItem('pinia/user/currentContactId')
			this.userData = {}
			this.currentContactId = null
			this.hasDismissedBecomeShareholderBanner = false
		},
		logout() {
			this.loginErrorMsg = ''
			this.isLoggedIn = false
			this.isLoggingIn = false
			this.reset()
			this.resetAllStores()
		},
		showWelcomeMessage(show = true) {
			this.userData = {
				...this.userData,
				firstTimeLogIn: show,
			}
		},

		async verified() {
			const { $axios } = useNuxtApp()
			try {
				const response = await userConfirmedAccountData($axios)

				if (response) {
					this.userData = {
						...this.userData,
						lastVerified: new Date().toISOString(),
					}
				}
			} catch (error) {
				// @ts-ignore
				this.resetErrorMsg = error.toString()
				throw error
			}
		},

		async loadCurrentContactId() {
			const { $axios, $config } = useNuxtApp()
			const userApi = new UserApi(undefined, $config.public.apiBaseHost, $axios)
			try {
				const response = await userApi.userGetLoggedInUser()
				this.currentContactId = response.data
			} catch (error) {
				console.error(error)
			}
		},
	},
})
