
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as icons_45page3Igen7wPPPpQaGb3H70Z9l4GCOL7PIdtiD3L6aYgAR0Meta } from "/home/vsts/work/1/s/node_modules/.pnpm/@nuxtjs+svg-sprite@1.0.2_magicast@0.3.5_rollup@4.36.0/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue?macro=true";
import { default as _91year_93v7faZ8ZcEKus0HcDOSk_sA9_45dIVVzuF7Z7Oqj03MJtYMeta } from "/home/vsts/work/1/s/pages/ac/[accountId]/account/[customerId]/[year].vue?macro=true";
import { default as indexrZHUoc5cflmdr09L2fVstIZJFb91t8eMHombQnyX0UEMeta } from "/home/vsts/work/1/s/pages/ac/[accountId]/account/[customerId]/index.vue?macro=true";
import { default as indexse9FctJNRbiX5JU1pl_FHJ1x8Hm_94iYqEswZZHlufMMeta } from "/home/vsts/work/1/s/pages/ac/[accountId]/index.vue?macro=true";
import { default as login_45callbackvzausbeJ1LKRb71Squ48L1anDMBqtJF53B_ACAgeJtgMeta } from "/home/vsts/work/1/s/pages/b2c/login-callback.vue?macro=true";
import { default as logout_45callbackdNbusAOREmkCvFkvU_456GtZ89zLJgt4oK4RWWgTZLKM8Meta } from "/home/vsts/work/1/s/pages/b2c/logout-callback.vue?macro=true";
import { default as callbackbCLJ7mz1CyARuHeOb8721VotfY0APxcXfH9snOqrGFwMeta } from "/home/vsts/work/1/s/pages/bankid/callback.vue?macro=true";
import { default as bestill_45skogbruksplandQGARSkUH0tNRJDjkHU6u8o0jH31aAKEj4cw6FLjvhsMeta } from "/home/vsts/work/1/s/pages/bestill-skogbruksplan.vue?macro=true";
import { default as indexEpb_45SFFkTAyNp_45wirC5QwyUTbYSgAMyQ52UVVBvrHfoMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/files/index.vue?macro=true";
import { default as indexGlGULyygRw4vYBSXv_Ou5Pybd2CEE5vk8Qvx5FZOJakMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/forward-run/index.vue?macro=true";
import { default as indexDnBzvF5xowvjThXlmS_45oYizQpiMdauJahiUexrpraJMMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/incidents/index.vue?macro=true";
import { default as indexHYtMXEi7lhHHEc8LmccPQc54R9ruEpUD21eJMwnbxQMMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/index.vue?macro=true";
import { default as indexNQkOOXOiSE1qrFrlZ1zY6znr87yTwKTR45Y_45hGSuAecMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/map-directions/index.vue?macro=true";
import { default as indexRuUDWXtP1tOUZ1Xz4Qtt0CDSFl1n2Su9wtOQwLYDO9AMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/map/index.vue?macro=true";
import { default as indexXr0BhETsg2K9zZtjHyqtVccH6ovPzdG4D1k5HlbU12sMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/produced/index.vue?macro=true";
import { default as indexIsgiYZAOIdJ3j0aCuAsNPjltZWk_8rK47ZshJoSTksQMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/reports/index.vue?macro=true";
import { default as assortments6FKTz5EWCg21nTB7Y4m_I1LAGWjBpo_todhI8F9R_qQMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/work-order/[machine]/assortments.vue?macro=true";
import { default as indexvon6wMHuMTaAnfAB3KZBAvgSxZoM0eQGD2oXGlLYGGgMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/work-order/[machine]/index.vue?macro=true";
import { default as reportKgBDvXMBOHcQRh3NbLQhb9zFjGctXehi3al_45D6a8f2QMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/work-order/[machine]/report.vue?macro=true";
import { default as indexEeJu1yCDg3IpairPsE_GmdYQ_BZl_45LTM9dMnLdZDOe4Meta } from "/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/work-order/index.vue?macro=true";
import { default as index75c91BHKHR3Z6IImI3CN1TvJq0azwHLBfzZHvDzdSRMMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/work-report/[machine]/index.vue?macro=true";
import { default as indexoh_9xvnhDs9kWXAoUfcTLAsc7U4GonESyfKsp0lCMCEMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/work-report/index.vue?macro=true";
import { default as _91orderId_93A6LpYMmBa2hHP44k_45dt_wbgt2HyysnSCIXDJxTACiUwMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId].vue?macro=true";
import { default as index2tmj_fGO2iYgfOemFGy3Ez3sHWeaCWXk9tPFvam5TaYMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/felling/index.vue?macro=true";
import { default as index1XH524GsHZAkXOfKn9ouWPlu7tOJwkJLJZ9e57sazG4Meta } from "/home/vsts/work/1/s/pages/co/[accountId]/index.vue?macro=true";
import { default as indexDPwXVlpF9mdxPQhppG_45UmZk2G3TSig3rZG7VojfSq5kMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/instructions/index.vue?macro=true";
import { default as completeeVCU5ibL0mShSZnIC8uUPxGR18tf4_ibxHVOg0yx0DAMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/silviculture/[orderId]/complete.vue?macro=true";
import { default as documentsZENqKnG6yK7Go64AmXncpvlcTUga5mGQfso5HvzqNFcMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/silviculture/[orderId]/documents.vue?macro=true";
import { default as indexQhUov2v24nZWm_45xlg1qxxKrLHGrnLPNq1_45bTvCLSXfkMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/silviculture/[orderId]/index.vue?macro=true";
import { default as map_45directions160uTYMWmxVUQk_VpKIMFmtbWGFwIICCQy0aEYYoIb0Meta } from "/home/vsts/work/1/s/pages/co/[accountId]/silviculture/[orderId]/map-directions.vue?macro=true";
import { default as mapaIL6AP1fnO0lzlbXq_45B6CJOunNW_zAPnLtMdDANh1pYMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/silviculture/[orderId]/map.vue?macro=true";
import { default as registercfXAJxqRvr4qbGGMUzEVa5foccdSNz4vxVCjrfWsvMkMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/silviculture/[orderId]/register.vue?macro=true";
import { default as indexJImmBqc7HKVpRlbA8Gy6jT1aCWs7_451_QwLh7T77_45OwQMeta } from "/home/vsts/work/1/s/pages/co/[accountId]/silviculture/index.vue?macro=true";
import { default as _91accountId_93yK4A8_45C7vwamXvOEVwJEZhmr3bQOvjQyN2otU4iKRHMMeta } from "/home/vsts/work/1/s/pages/co/[accountId].vue?macro=true";
import { default as instructionsOzgzuvPMOuSzViv7HymLJLAB_wJ9oJ4IqmTCYsfNHZcMeta } from "/home/vsts/work/1/s/pages/co/instructions.vue?macro=true";
import { default as _91year_93XFc7H_14A2XemMAt8_NuvtupdgvF_45LBDFZLCAX5gBUMMeta } from "/home/vsts/work/1/s/pages/fo/[accountId]/accounting/[year].vue?macro=true";
import { default as indexmhEHsPUBGekeBeFg5Qo14ww273WsjAxSplNSaQ0r1d0Meta } from "/home/vsts/work/1/s/pages/fo/[accountId]/accounting/index.vue?macro=true";
import { default as indexSxWtJKlxSb_4jkHEtm69udDwl7wYLI9ovH_45k9mHTT5UMeta } from "/home/vsts/work/1/s/pages/fo/[accountId]/agreements/[contractId]/index.vue?macro=true";
import { default as indexKfwTqa6wvETg4DydTDZMMt8fvRpCJaCvlM3_zQCGYH0Meta } from "/home/vsts/work/1/s/pages/fo/[accountId]/agreements/index.vue?macro=true";
import { default as EnvironmentReportFormW5ADO3H1Pgyb8LR5ZQcbAyycmAL4kGDmAsVlpbbHJxEMeta } from "/home/vsts/work/1/s/pages/fo/[accountId]/assignments/contracts/[contractId]/EnvironmentReportForm.vue?macro=true";
import { default as indexmpVb2oen9z0FSJTaWpQKbx8NOetw4FZZQ6BGSxuf8x0Meta } from "/home/vsts/work/1/s/pages/fo/[accountId]/assignments/contracts/[contractId]/index.vue?macro=true";
import { default as track_45damage_45repairOzcvEgQH_ZpcsphR1G1wYH56VTClZpD_yDJylcFUNx0Meta } from "/home/vsts/work/1/s/pages/fo/[accountId]/assignments/contracts/[contractId]/track-damage-repair.vue?macro=true";
import { default as indexJuDjJN1K6ON_45hMg7b6pEII7mmEQjEdi79ftxIfK1ehAMeta } from "/home/vsts/work/1/s/pages/fo/[accountId]/assignments/contracts/index.vue?macro=true";
import { default as indexwfocR6an90gMfg1rJIIbfMuD_6Ah_45zieoLlJUsaJKBQMeta } from "/home/vsts/work/1/s/pages/fo/[accountId]/assignments/index.vue?macro=true";
import { default as indexIG8Ejgq9KC4d1yhgnqBq2951QCMKGRAIQZSlt9IVBcoMeta } from "/home/vsts/work/1/s/pages/fo/[accountId]/assignments/orders/[orderId]/index.vue?macro=true";
import { default as indexziO4sFqoo849_451ZICSUbLDNHISF67b6_45oesbMGGi_VQMeta } from "/home/vsts/work/1/s/pages/fo/[accountId]/assignments/orders/index.vue?macro=true";
import { default as index7tj4PLbc2qfHTbjA9aoBR_6rBWyujo_45k4mzlL1iZjt4Meta } from "/home/vsts/work/1/s/pages/fo/[accountId]/contact/index.vue?macro=true";
import { default as indexwrpJgLnkPAmKik_kG0DcMdv3uaW2M0cHjoQcSLneAtMMeta } from "/home/vsts/work/1/s/pages/fo/[accountId]/documents/index.vue?macro=true";
import { default as _91agreementId_93RxmkVlKDic55XAGMTJI_45K6BkVvSWOHTQKKkV_458Efm4kMeta } from "/home/vsts/work/1/s/pages/fo/[accountId]/environmental-agreements/[agreementId].vue?macro=true";
import { default as indexXu1CamP9b4hjRIuw0RlSK4uBLjmQfqMm3_GEwrxHe5EMeta } from "/home/vsts/work/1/s/pages/fo/[accountId]/index.vue?macro=true";
import { default as unsigned_45documentsCMyWgGKNjMoytkQjdbEYHDKd9vx4UJMjSZ3Ybhr0Cd0Meta } from "/home/vsts/work/1/s/pages/fo/unsigned-documents.vue?macro=true";
import { default as indexLiSwOG_45EnsyrzSv9J7wVbtUE3hBMYBvu0fyT1w_45g_y8Meta } from "/home/vsts/work/1/s/pages/index.vue?macro=true";
import { default as index6UuoBcivor3nczHkGQiuXAxS2IwrtV_U_45lm7nYHJdh0Meta } from "/home/vsts/work/1/s/pages/my-account/ac/[accountId]/index.vue?macro=true";
import { default as index8kjhryTO0jLCHyTkRp6MhDFruqwANLIGNs0Q0BXIv_45UMeta } from "/home/vsts/work/1/s/pages/my-account/ac/[accountId]/users/index.vue?macro=true";
import { default as indexE9qPNk09HAMh8_45lrEDV78VkoM5Kl0lXbSDnrWgkpSmgMeta } from "/home/vsts/work/1/s/pages/my-account/co/[accountId]/index.vue?macro=true";
import { default as indexpBfOl6HKUCM5QGQ7K3o3mg7IfNl1QxlaEDB31ecHHb8Meta } from "/home/vsts/work/1/s/pages/my-account/co/[accountId]/teams/index.vue?macro=true";
import { default as indexgBbIdUVHvOpcmBH1gCg_4WnLeWEAIY7_458Qi089uU4LsMeta } from "/home/vsts/work/1/s/pages/my-account/co/[accountId]/users/index.vue?macro=true";
import { default as indexDlg5iGxCc4OmljzjiZIsYxtV7IGRt4tp5c7pEXH2uKwMeta } from "/home/vsts/work/1/s/pages/my-account/consent/index.vue?macro=true";
import { default as indexxiYRQwyd_45vqHZa_45MUKZ0x4hdGRziVoV_45K8WVwfxjyIUMeta } from "/home/vsts/work/1/s/pages/my-account/fo/[accountId]/accountant/index.vue?macro=true";
import { default as indexOdYXqQkmyCa0FnzLsrO99aOeV6g96QcdhwyY3_45LftHQMeta } from "/home/vsts/work/1/s/pages/my-account/fo/[accountId]/environmental-information/index.vue?macro=true";
import { default as indexoU8SuxHxHa8ht4sfsIqDu4J6Vyl_45GJ__bUWyTnpiuagMeta } from "/home/vsts/work/1/s/pages/my-account/fo/[accountId]/general/index.vue?macro=true";
import { default as indexA_XMVVhxBWw_oF0gBoiSQFiPMslaIkSPHa9cw_SrxzEMeta } from "/home/vsts/work/1/s/pages/my-account/fo/[accountId]/index.vue?macro=true";
import { default as index0f9xmoPx6VR1EcJrjT4aYlaT6z58H5sNWZmDgoqh4uYMeta } from "/home/vsts/work/1/s/pages/my-account/fo/[accountId]/properties/index.vue?macro=true";
import { default as delegate_45powers_45of_45attorneyPX66aRZHuqDzaEBcCu5lKE0CUAIqPpTOQgepC1BGpiwMeta } from "/home/vsts/work/1/s/pages/my-account/fo/[accountId]/property-legal-rights/delegate-powers-of-attorney.vue?macro=true";
import { default as indexA573WjN9QQSB_45ihvfJnz6dBU4gWWaBxFpglkMvX1K4QMeta } from "/home/vsts/work/1/s/pages/my-account/fo/[accountId]/property-legal-rights/index.vue?macro=true";
import { default as approveflv1CHakCbaXfp3dUR6JjxVwVph5CuzvXJN2pcOTIPEMeta } from "/home/vsts/work/1/s/pages/my-account/fo/[accountId]/property-legal-rights/property/[propertyId]/power-of-attorney/[delegationRefCode]/approve.vue?macro=true";
import { default as delete40XRwkoX6jM3dLdITJCZRrkLfHLtaCCN0bjm3iQgphwMeta } from "/home/vsts/work/1/s/pages/my-account/fo/[accountId]/property-legal-rights/property/[propertyId]/power-of-attorney/[delegationRefCode]/delete.vue?macro=true";
import { default as editYJUaWrWiEBzNO3G0VnUhmLKwb5btgx3vEn8H5zFZekcMeta } from "/home/vsts/work/1/s/pages/my-account/fo/[accountId]/property-legal-rights/property/[propertyId]/power-of-attorney/[delegationRefCode]/edit.vue?macro=true";
import { default as delegatecgab_K4rQCXCf0MxQtCio_InpN_45PxBVmPjDPPAORkP0Meta } from "/home/vsts/work/1/s/pages/my-account/fo/[accountId]/property-legal-rights/property/[propertyId]/power-of-attorney/delegate.vue?macro=true";
import { default as cancelL_45M7AmOn01wRh_HRmezgIvgB7T9J_ozxGrFcXph9PBgMeta } from "/home/vsts/work/1/s/pages/my-account/fo/[accountId]/property-legal-rights/property/[propertyId]/right-of-use/[delegationRefCode]/cancel.vue?macro=true";
import { default as delegateCUcfzxJn8nMkf4JGVubrb2GhCM5Z8pSisGPksWGvl_45cMeta } from "/home/vsts/work/1/s/pages/my-account/fo/[accountId]/property-legal-rights/property/[propertyId]/right-of-use/delegate.vue?macro=true";
import { default as ShareholderInformation12wJtBz4uNd3ZWGEQyxmK0G9QgfjFAYVFMJS1qxQrN8Meta } from "/home/vsts/work/1/s/pages/my-account/fo/[accountId]/ShareholderInformation.vue?macro=true";
import { default as indexpVLnMuEt969eo8PnH59NrvC50e_45kd8A54GBZJ0JZe4EMeta } from "/home/vsts/work/1/s/pages/my-account/index.vue?macro=true";
import { default as indexmKxBRbqTtFhSyLW6q_j7P1_uzWukDZr9Rf_99h1dLmUMeta } from "/home/vsts/work/1/s/pages/my-account/pl/[accountId]/index.vue?macro=true";
import { default as indexpMkpFs1JvGwMz8BlDPwYcr_45GkOqhQZ_45iFhD2zf79VF0Meta } from "/home/vsts/work/1/s/pages/my-account/settings/index.vue?macro=true";
import { default as indexUo2TGhnB3JhUQbu_45M0Ut7G0OsxiewaK9jqqSNIFi_45IEMeta } from "/home/vsts/work/1/s/pages/my-account/user-access/index.vue?macro=true";
import { default as delete_ulm0ZCboR5DPDCbO3kPJzRc9K2dSAU_sSsXnKIeGCIMeta } from "/home/vsts/work/1/s/pages/my-account/user-access/property/[propertyId]/power-of-attorney/[delegationRefCode]/delete.vue?macro=true";
import { default as canceld3S3GoVcFLOnCSqspNdnpCb6Zs_454P2Lc4Scx26qv0bgMeta } from "/home/vsts/work/1/s/pages/my-account/user-access/property/[propertyId]/right-of-use/[delegationRefCode]/cancel.vue?macro=true";
import { default as delegateJ_bbnru_HAjnnGqa5Y_45I3jPU1hjkmAgPANgFjnruHJgMeta } from "/home/vsts/work/1/s/pages/my-account/user-access/property/[propertyId]/right-of-use/delegate.vue?macro=true";
import { default as right_45of_45usecC07X7iUxxD4YmbIuOu8Bb3RUVPUgJ_45ImhvvZIk1N3YMeta } from "/home/vsts/work/1/s/pages/my-account/user-access/property/[propertyId]/right-of-use.vue?macro=true";
import { default as settings_45OUZMjqjoYMSIcL_45UkAOrl2ZtQ7N3GmdqCE0aU_45lyaMMeta } from "/home/vsts/work/1/s/pages/my-account/user-access/property/[propertyId]/settings.vue?macro=true";
import { default as request_45power_45of_45attorneydrUKqHeyPRiZAYZLBDW9e0i_x7M6ZyqmDfGJBvleclgMeta } from "/home/vsts/work/1/s/pages/my-account/user-access/request-power-of-attorney.vue?macro=true";
import { default as indexQ7uNKDXIvBtou3bDPrxArlSBSo20gUNhUaDguplYpcsMeta } from "/home/vsts/work/1/s/pages/pl/[accountId]/[orderId]/index.vue?macro=true";
import { default as index0omGYmKJ8VXUlXmg3TK36L7BkyWMYxNq4tNGGCyEjJoMeta } from "/home/vsts/work/1/s/pages/pl/[accountId]/index.vue?macro=true";
import { default as indexeQbGrTLuZ3raKde6bJxRIq6bGfwtbywTNW0_45kkxR1XMMeta } from "/home/vsts/work/1/s/pages/redirect/po/[orderId]/index.vue?macro=true";
import { default as about_45youITZwBMtttqgGSm57j9VRs1eL_pr8hb1SEM_WIFK1AJ4Meta } from "/home/vsts/work/1/s/pages/register/new-forest-owner/about-you.vue?macro=true";
import { default as allma7Rk1LwsJ2wJg3gYsaoor9L1aPvLdrmS_45r77Ep7b_45FWIMeta } from "/home/vsts/work/1/s/pages/register/new-forest-owner/allma.vue?macro=true";
import { default as bank_45account_mRG4oAC7jDmrFArOY6rs4XLGDk_MjQx9UwFV_IXVKwMeta } from "/home/vsts/work/1/s/pages/register/new-forest-owner/bank-account.vue?macro=true";
import { default as company9o0CXhidYQGBCbWd10kHIq3WULTnzx6gTsoU79fWnycMeta } from "/home/vsts/work/1/s/pages/register/new-forest-owner/company.vue?macro=true";
import { default as confirm_45access7OBwkZCNFaDZ77OQpXujUxd5KNiCzF_454_2RGIo5ZzREMeta } from "/home/vsts/work/1/s/pages/register/new-forest-owner/confirm-access.vue?macro=true";
import { default as contact_45infosnTDgbnrRs98zBnuW1LMZKlikkzw_NJkKCxhPR7Fzq4Meta } from "/home/vsts/work/1/s/pages/register/new-forest-owner/contact-info.vue?macro=true";
import { default as donedv5IfRQ_45pmC1w3nBGJbji6UxH2ScJdP5oLkMewonsdcMeta } from "/home/vsts/work/1/s/pages/register/new-forest-owner/done.vue?macro=true";
import { default as indexf0fvQbINwUP9ssF7ZYsnGqJJW8uSLZ2P7jR6Tih5vYIMeta } from "/home/vsts/work/1/s/pages/register/new-forest-owner/index.vue?macro=true";
import { default as login13KfNxGwXNXlK2ZC_45nJ2aruoM0iJjZmUacGMqKkkk4UMeta } from "/home/vsts/work/1/s/pages/register/new-forest-owner/login.vue?macro=true";
import { default as share_45holderj5w92O0cWg6Xer0y5JlcYpSSEKg_dCI5jNtjI1wN1jkMeta } from "/home/vsts/work/1/s/pages/register/new-forest-owner/share-holder.vue?macro=true";
import { default as skog_45subscriptionT2MlRQ_bz5WxeJQYBIlBa9UONIqNPo4DP4USIQhTXygMeta } from "/home/vsts/work/1/s/pages/register/new-forest-owner/skog-subscription.vue?macro=true";
import { default as summaryOTaARFgXChzrDJy864CAA2otlpGJNQ3a91_45uGRKlIbIMeta } from "/home/vsts/work/1/s/pages/register/new-forest-owner/summary.vue?macro=true";
import { default as transferHPmAIxiIQXDw_ZHUYGL1lyd4wqsGphS4RJcOVbgZ2TsMeta } from "/home/vsts/work/1/s/pages/register/new-forest-owner/transfer.vue?macro=true";
import { default as new_45forest_45ownermEOKEn8Pve9QXmauTUxKsLJYkfW4EFvxUnheIEMX8cMMeta } from "/home/vsts/work/1/s/pages/register/new-forest-owner.vue?macro=true";
import { default as completingruWlB4lnlVN0Vz_45aydaUWsCqjYhN8_wEepCyfWKrOmAMeta } from "/home/vsts/work/1/s/pages/register/simple/[contactId]/completing.vue?macro=true";
import { default as confirm_45accessABiJXrxvqfg6gV00cIuxN7BtBhxTk2JoAP1_YGIPkQYMeta } from "/home/vsts/work/1/s/pages/register/simple/[contactId]/confirm-access.vue?macro=true";
import { default as contact_45infoTNoPDx5kZwteZIUhXeWA_XlFRbbcRYuf4JGjmQGr030Meta } from "/home/vsts/work/1/s/pages/register/simple/[contactId]/contact-info.vue?macro=true";
import { default as indexmI4QJRrxbRE2CHhkj3NUDa3kCNZ6xzrs2vMnzSAA5NEMeta } from "/home/vsts/work/1/s/pages/register/simple/[contactId]/index.vue?macro=true";
import { default as loginUK9oip4CZvWF1hrG3_wi6mc6hR9lPM_45aGNHsb0zqpTsMeta } from "/home/vsts/work/1/s/pages/register/simple/[contactId]/login.vue?macro=true";
import { default as successjJ7aB23SQI7Q11Ms_PaQks96hlJfBoou8_45Q0Gl_45ZW2kMeta } from "/home/vsts/work/1/s/pages/register/simple/[contactId]/success.vue?macro=true";
import { default as summaryi999cpHeTIkPuJ7_45uKKaQm7Z_bvbclelns0ckb_45aGm8Meta } from "/home/vsts/work/1/s/pages/register/simple/[contactId]/summary.vue?macro=true";
import { default as _91contactId_93i1hVDq9AuvIvAQ7q8nhyFttxOdkIQdI6AmNCP1WpCO0Meta } from "/home/vsts/work/1/s/pages/register/simple/[contactId].vue?macro=true";
import { default as _91id_93qhB3fc7YM2REDLaauYiD6_pQ_xGqtMrkCwxmnSjRag4Meta } from "/home/vsts/work/1/s/pages/resetpassword/[id].vue?macro=true";
export default [
  {
    name: "icons-page",
    path: "/_icons",
    meta: {"layout":"svg-sprite"},
    component: () => import("/home/vsts/work/1/s/node_modules/.pnpm/@nuxtjs+svg-sprite@1.0.2_magicast@0.3.5_rollup@4.36.0/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue")
  },
  {
    name: "ac-accountId-account-customerId-year",
    path: "/ac/:accountId()/account/:customerId()/:year()",
    component: () => import("/home/vsts/work/1/s/pages/ac/[accountId]/account/[customerId]/[year].vue")
  },
  {
    name: "ac-accountId-account-customerId",
    path: "/ac/:accountId()/account/:customerId()",
    component: () => import("/home/vsts/work/1/s/pages/ac/[accountId]/account/[customerId]/index.vue")
  },
  {
    name: "ac-accountId",
    path: "/ac/:accountId()",
    component: () => import("/home/vsts/work/1/s/pages/ac/[accountId]/index.vue")
  },
  {
    name: "b2c-login-callback",
    path: "/b2c/login-callback",
    meta: login_45callbackvzausbeJ1LKRb71Squ48L1anDMBqtJF53B_ACAgeJtgMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/b2c/login-callback.vue")
  },
  {
    name: "b2c-logout-callback",
    path: "/b2c/logout-callback",
    meta: logout_45callbackdNbusAOREmkCvFkvU_456GtZ89zLJgt4oK4RWWgTZLKM8Meta || {},
    component: () => import("/home/vsts/work/1/s/pages/b2c/logout-callback.vue")
  },
  {
    name: "bankid-callback",
    path: "/bankid/callback",
    meta: callbackbCLJ7mz1CyARuHeOb8721VotfY0APxcXfH9snOqrGFwMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/bankid/callback.vue")
  },
  {
    name: "bestill-skogbruksplan",
    path: "/bestill-skogbruksplan",
    meta: bestill_45skogbruksplandQGARSkUH0tNRJDjkHU6u8o0jH31aAKEj4cw6FLjvhsMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/bestill-skogbruksplan.vue")
  },
  {
    name: _91accountId_93yK4A8_45C7vwamXvOEVwJEZhmr3bQOvjQyN2otU4iKRHMMeta?.name,
    path: "/co/:accountId()",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId].vue"),
    children: [
  {
    name: _91orderId_93A6LpYMmBa2hHP44k_45dt_wbgt2HyysnSCIXDJxTACiUwMeta?.name,
    path: "felling/:orderId()",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId].vue"),
    children: [
  {
    name: "co-accountId-felling-orderId-files",
    path: "files",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/files/index.vue")
  },
  {
    name: "co-accountId-felling-orderId-forward-run",
    path: "forward-run",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/forward-run/index.vue")
  },
  {
    name: "co-accountId-felling-orderId-incidents",
    path: "incidents",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/incidents/index.vue")
  },
  {
    name: "co-accountId-felling-orderId",
    path: "",
    meta: indexHYtMXEi7lhHHEc8LmccPQc54R9ruEpUD21eJMwnbxQMMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/index.vue")
  },
  {
    name: "co-accountId-felling-orderId-map-directions",
    path: "map-directions",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/map-directions/index.vue")
  },
  {
    name: "co-accountId-felling-orderId-map",
    path: "map",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/map/index.vue")
  },
  {
    name: "co-accountId-felling-orderId-produced",
    path: "produced",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/produced/index.vue")
  },
  {
    name: "co-accountId-felling-orderId-reports",
    path: "reports",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/reports/index.vue")
  },
  {
    name: "co-accountId-felling-orderId-work-order-machine-assortments",
    path: "work-order/:machine()/assortments",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/work-order/[machine]/assortments.vue")
  },
  {
    name: "co-accountId-felling-orderId-work-order-machine",
    path: "work-order/:machine()",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/work-order/[machine]/index.vue")
  },
  {
    name: "co-accountId-felling-orderId-work-order-machine-report",
    path: "work-order/:machine()/report",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/work-order/[machine]/report.vue")
  },
  {
    name: "co-accountId-felling-orderId-work-order",
    path: "work-order",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/work-order/index.vue")
  },
  {
    name: "co-accountId-felling-orderId-work-report-machine",
    path: "work-report/:machine()",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/work-report/[machine]/index.vue")
  },
  {
    name: "co-accountId-felling-orderId-work-report",
    path: "work-report",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/felling/[orderId]/work-report/index.vue")
  }
]
  },
  {
    name: "co-accountId-felling",
    path: "felling",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/felling/index.vue")
  },
  {
    name: "co-accountId",
    path: "",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/index.vue")
  },
  {
    name: "co-accountId-instructions",
    path: "instructions",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/instructions/index.vue")
  },
  {
    name: "co-accountId-silviculture-orderId-complete",
    path: "silviculture/:orderId()/complete",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/silviculture/[orderId]/complete.vue")
  },
  {
    name: "co-accountId-silviculture-orderId-documents",
    path: "silviculture/:orderId()/documents",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/silviculture/[orderId]/documents.vue")
  },
  {
    name: "co-accountId-silviculture-orderId",
    path: "silviculture/:orderId()",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/silviculture/[orderId]/index.vue")
  },
  {
    name: "co-accountId-silviculture-orderId-map-directions",
    path: "silviculture/:orderId()/map-directions",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/silviculture/[orderId]/map-directions.vue")
  },
  {
    name: "co-accountId-silviculture-orderId-map",
    path: "silviculture/:orderId()/map",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/silviculture/[orderId]/map.vue")
  },
  {
    name: "co-accountId-silviculture-orderId-register",
    path: "silviculture/:orderId()/register",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/silviculture/[orderId]/register.vue")
  },
  {
    name: "co-accountId-silviculture",
    path: "silviculture",
    component: () => import("/home/vsts/work/1/s/pages/co/[accountId]/silviculture/index.vue")
  }
]
  },
  {
    name: "co-instructions",
    path: "/co/instructions",
    meta: instructionsOzgzuvPMOuSzViv7HymLJLAB_wJ9oJ4IqmTCYsfNHZcMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/co/instructions.vue")
  },
  {
    name: "fo-accountId-accounting-year",
    path: "/fo/:accountId()/accounting/:year()",
    component: () => import("/home/vsts/work/1/s/pages/fo/[accountId]/accounting/[year].vue")
  },
  {
    name: "fo-accountId-accounting",
    path: "/fo/:accountId()/accounting",
    component: () => import("/home/vsts/work/1/s/pages/fo/[accountId]/accounting/index.vue")
  },
  {
    name: "fo-accountId-agreements-contractId",
    path: "/fo/:accountId()/agreements/:contractId()",
    component: () => import("/home/vsts/work/1/s/pages/fo/[accountId]/agreements/[contractId]/index.vue")
  },
  {
    name: "fo-accountId-agreements",
    path: "/fo/:accountId()/agreements",
    component: () => import("/home/vsts/work/1/s/pages/fo/[accountId]/agreements/index.vue")
  },
  {
    name: "fo-accountId-assignments-contracts-contractId-EnvironmentReportForm",
    path: "/fo/:accountId()/assignments/contracts/:contractId()/EnvironmentReportForm",
    component: () => import("/home/vsts/work/1/s/pages/fo/[accountId]/assignments/contracts/[contractId]/EnvironmentReportForm.vue")
  },
  {
    name: "fo-accountId-assignments-contracts-contractId",
    path: "/fo/:accountId()/assignments/contracts/:contractId()",
    component: () => import("/home/vsts/work/1/s/pages/fo/[accountId]/assignments/contracts/[contractId]/index.vue")
  },
  {
    name: "fo-accountId-assignments-contracts-contractId-track-damage-repair",
    path: "/fo/:accountId()/assignments/contracts/:contractId()/track-damage-repair",
    component: () => import("/home/vsts/work/1/s/pages/fo/[accountId]/assignments/contracts/[contractId]/track-damage-repair.vue")
  },
  {
    name: "fo-accountId-assignments-contracts",
    path: "/fo/:accountId()/assignments/contracts",
    component: () => import("/home/vsts/work/1/s/pages/fo/[accountId]/assignments/contracts/index.vue")
  },
  {
    name: "fo-accountId-assignments",
    path: "/fo/:accountId()/assignments",
    component: () => import("/home/vsts/work/1/s/pages/fo/[accountId]/assignments/index.vue")
  },
  {
    name: "fo-accountId-assignments-orders-orderId",
    path: "/fo/:accountId()/assignments/orders/:orderId()",
    component: () => import("/home/vsts/work/1/s/pages/fo/[accountId]/assignments/orders/[orderId]/index.vue")
  },
  {
    name: "fo-accountId-assignments-orders",
    path: "/fo/:accountId()/assignments/orders",
    component: () => import("/home/vsts/work/1/s/pages/fo/[accountId]/assignments/orders/index.vue")
  },
  {
    name: "fo-accountId-contact",
    path: "/fo/:accountId()/contact",
    component: () => import("/home/vsts/work/1/s/pages/fo/[accountId]/contact/index.vue")
  },
  {
    name: "fo-accountId-documents",
    path: "/fo/:accountId()/documents",
    component: () => import("/home/vsts/work/1/s/pages/fo/[accountId]/documents/index.vue")
  },
  {
    name: "fo-accountId-environmental-agreements-agreementId",
    path: "/fo/:accountId()/environmental-agreements/:agreementId()",
    meta: _91agreementId_93RxmkVlKDic55XAGMTJI_45K6BkVvSWOHTQKKkV_458Efm4kMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/fo/[accountId]/environmental-agreements/[agreementId].vue")
  },
  {
    name: "fo-accountId",
    path: "/fo/:accountId()",
    component: () => import("/home/vsts/work/1/s/pages/fo/[accountId]/index.vue")
  },
  {
    name: "fo-unsigned-documents",
    path: "/fo/unsigned-documents",
    component: () => import("/home/vsts/work/1/s/pages/fo/unsigned-documents.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/vsts/work/1/s/pages/index.vue")
  },
  {
    name: "my-account-ac-accountId",
    path: "/my-account/ac/:accountId()",
    component: () => import("/home/vsts/work/1/s/pages/my-account/ac/[accountId]/index.vue")
  },
  {
    name: "my-account-ac-accountId-users",
    path: "/my-account/ac/:accountId()/users",
    component: () => import("/home/vsts/work/1/s/pages/my-account/ac/[accountId]/users/index.vue")
  },
  {
    name: "my-account-co-accountId",
    path: "/my-account/co/:accountId()",
    component: () => import("/home/vsts/work/1/s/pages/my-account/co/[accountId]/index.vue")
  },
  {
    name: "my-account-co-accountId-teams",
    path: "/my-account/co/:accountId()/teams",
    component: () => import("/home/vsts/work/1/s/pages/my-account/co/[accountId]/teams/index.vue")
  },
  {
    name: "my-account-co-accountId-users",
    path: "/my-account/co/:accountId()/users",
    component: () => import("/home/vsts/work/1/s/pages/my-account/co/[accountId]/users/index.vue")
  },
  {
    name: "my-account-consent",
    path: "/my-account/consent",
    component: () => import("/home/vsts/work/1/s/pages/my-account/consent/index.vue")
  },
  {
    name: "my-account-fo-accountId-accountant",
    path: "/my-account/fo/:accountId()/accountant",
    component: () => import("/home/vsts/work/1/s/pages/my-account/fo/[accountId]/accountant/index.vue")
  },
  {
    name: "my-account-fo-accountId-environmental-information",
    path: "/my-account/fo/:accountId()/environmental-information",
    component: () => import("/home/vsts/work/1/s/pages/my-account/fo/[accountId]/environmental-information/index.vue")
  },
  {
    name: "my-account-fo-accountId-general",
    path: "/my-account/fo/:accountId()/general",
    component: () => import("/home/vsts/work/1/s/pages/my-account/fo/[accountId]/general/index.vue")
  },
  {
    name: "my-account-fo-accountId",
    path: "/my-account/fo/:accountId()",
    component: () => import("/home/vsts/work/1/s/pages/my-account/fo/[accountId]/index.vue")
  },
  {
    name: "my-account-fo-accountId-properties",
    path: "/my-account/fo/:accountId()/properties",
    component: () => import("/home/vsts/work/1/s/pages/my-account/fo/[accountId]/properties/index.vue")
  },
  {
    name: "my-account-fo-accountId-property-legal-rights-delegate-powers-of-attorney",
    path: "/my-account/fo/:accountId()/property-legal-rights/delegate-powers-of-attorney",
    meta: delegate_45powers_45of_45attorneyPX66aRZHuqDzaEBcCu5lKE0CUAIqPpTOQgepC1BGpiwMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/my-account/fo/[accountId]/property-legal-rights/delegate-powers-of-attorney.vue")
  },
  {
    name: "my-account-fo-accountId-property-legal-rights",
    path: "/my-account/fo/:accountId()/property-legal-rights",
    component: () => import("/home/vsts/work/1/s/pages/my-account/fo/[accountId]/property-legal-rights/index.vue")
  },
  {
    name: "my-account-fo-accountId-property-legal-rights-property-propertyId-power-of-attorney-delegationRefCode-approve",
    path: "/my-account/fo/:accountId()/property-legal-rights/property/:propertyId()/power-of-attorney/:delegationRefCode()/approve",
    meta: approveflv1CHakCbaXfp3dUR6JjxVwVph5CuzvXJN2pcOTIPEMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/my-account/fo/[accountId]/property-legal-rights/property/[propertyId]/power-of-attorney/[delegationRefCode]/approve.vue")
  },
  {
    name: "my-account-fo-accountId-property-legal-rights-property-propertyId-power-of-attorney-delegationRefCode-delete",
    path: "/my-account/fo/:accountId()/property-legal-rights/property/:propertyId()/power-of-attorney/:delegationRefCode()/delete",
    meta: delete40XRwkoX6jM3dLdITJCZRrkLfHLtaCCN0bjm3iQgphwMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/my-account/fo/[accountId]/property-legal-rights/property/[propertyId]/power-of-attorney/[delegationRefCode]/delete.vue")
  },
  {
    name: "my-account-fo-accountId-property-legal-rights-property-propertyId-power-of-attorney-delegationRefCode-edit",
    path: "/my-account/fo/:accountId()/property-legal-rights/property/:propertyId()/power-of-attorney/:delegationRefCode()/edit",
    meta: editYJUaWrWiEBzNO3G0VnUhmLKwb5btgx3vEn8H5zFZekcMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/my-account/fo/[accountId]/property-legal-rights/property/[propertyId]/power-of-attorney/[delegationRefCode]/edit.vue")
  },
  {
    name: "my-account-fo-accountId-property-legal-rights-property-propertyId-power-of-attorney-delegate",
    path: "/my-account/fo/:accountId()/property-legal-rights/property/:propertyId()/power-of-attorney/delegate",
    meta: delegatecgab_K4rQCXCf0MxQtCio_InpN_45PxBVmPjDPPAORkP0Meta || {},
    component: () => import("/home/vsts/work/1/s/pages/my-account/fo/[accountId]/property-legal-rights/property/[propertyId]/power-of-attorney/delegate.vue")
  },
  {
    name: "my-account-fo-accountId-property-legal-rights-property-propertyId-right-of-use-delegationRefCode-cancel",
    path: "/my-account/fo/:accountId()/property-legal-rights/property/:propertyId()/right-of-use/:delegationRefCode()/cancel",
    meta: cancelL_45M7AmOn01wRh_HRmezgIvgB7T9J_ozxGrFcXph9PBgMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/my-account/fo/[accountId]/property-legal-rights/property/[propertyId]/right-of-use/[delegationRefCode]/cancel.vue")
  },
  {
    name: "my-account-fo-accountId-property-legal-rights-property-propertyId-right-of-use-delegate",
    path: "/my-account/fo/:accountId()/property-legal-rights/property/:propertyId()/right-of-use/delegate",
    meta: delegateCUcfzxJn8nMkf4JGVubrb2GhCM5Z8pSisGPksWGvl_45cMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/my-account/fo/[accountId]/property-legal-rights/property/[propertyId]/right-of-use/delegate.vue")
  },
  {
    name: "my-account-fo-accountId-ShareholderInformation",
    path: "/my-account/fo/:accountId()/ShareholderInformation",
    component: () => import("/home/vsts/work/1/s/pages/my-account/fo/[accountId]/ShareholderInformation.vue")
  },
  {
    name: "my-account",
    path: "/my-account",
    component: () => import("/home/vsts/work/1/s/pages/my-account/index.vue")
  },
  {
    name: "my-account-pl-accountId",
    path: "/my-account/pl/:accountId()",
    component: () => import("/home/vsts/work/1/s/pages/my-account/pl/[accountId]/index.vue")
  },
  {
    name: "my-account-settings",
    path: "/my-account/settings",
    component: () => import("/home/vsts/work/1/s/pages/my-account/settings/index.vue")
  },
  {
    name: "my-account-user-access",
    path: "/my-account/user-access",
    component: () => import("/home/vsts/work/1/s/pages/my-account/user-access/index.vue")
  },
  {
    name: "my-account-user-access-property-propertyId-power-of-attorney-delegationRefCode-delete",
    path: "/my-account/user-access/property/:propertyId()/power-of-attorney/:delegationRefCode()/delete",
    meta: delete_ulm0ZCboR5DPDCbO3kPJzRc9K2dSAU_sSsXnKIeGCIMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/my-account/user-access/property/[propertyId]/power-of-attorney/[delegationRefCode]/delete.vue")
  },
  {
    name: "my-account-user-access-property-propertyId-right-of-use",
    path: "/my-account/user-access/property/:propertyId()/right-of-use",
    meta: right_45of_45usecC07X7iUxxD4YmbIuOu8Bb3RUVPUgJ_45ImhvvZIk1N3YMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/my-account/user-access/property/[propertyId]/right-of-use.vue"),
    children: [
  {
    name: "my-account-user-access-property-propertyId-right-of-use-delegationRefCode-cancel",
    path: ":delegationRefCode()/cancel",
    meta: canceld3S3GoVcFLOnCSqspNdnpCb6Zs_454P2Lc4Scx26qv0bgMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/my-account/user-access/property/[propertyId]/right-of-use/[delegationRefCode]/cancel.vue")
  },
  {
    name: "my-account-user-access-property-propertyId-right-of-use-delegate",
    path: "delegate",
    meta: delegateJ_bbnru_HAjnnGqa5Y_45I3jPU1hjkmAgPANgFjnruHJgMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/my-account/user-access/property/[propertyId]/right-of-use/delegate.vue")
  }
]
  },
  {
    name: "my-account-user-access-property-propertyId-settings",
    path: "/my-account/user-access/property/:propertyId()/settings",
    meta: settings_45OUZMjqjoYMSIcL_45UkAOrl2ZtQ7N3GmdqCE0aU_45lyaMMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/my-account/user-access/property/[propertyId]/settings.vue")
  },
  {
    name: "my-account-user-access-request-power-of-attorney",
    path: "/my-account/user-access/request-power-of-attorney",
    meta: request_45power_45of_45attorneydrUKqHeyPRiZAYZLBDW9e0i_x7M6ZyqmDfGJBvleclgMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/my-account/user-access/request-power-of-attorney.vue")
  },
  {
    name: "pl-accountId-orderId",
    path: "/pl/:accountId()/:orderId()",
    component: () => import("/home/vsts/work/1/s/pages/pl/[accountId]/[orderId]/index.vue")
  },
  {
    name: "pl-accountId",
    path: "/pl/:accountId()",
    component: () => import("/home/vsts/work/1/s/pages/pl/[accountId]/index.vue")
  },
  {
    name: "redirect-po-orderId",
    path: "/redirect/po/:orderId()",
    component: () => import("/home/vsts/work/1/s/pages/redirect/po/[orderId]/index.vue")
  },
  {
    name: new_45forest_45ownermEOKEn8Pve9QXmauTUxKsLJYkfW4EFvxUnheIEMX8cMMeta?.name,
    path: "/register/new-forest-owner",
    meta: new_45forest_45ownermEOKEn8Pve9QXmauTUxKsLJYkfW4EFvxUnheIEMX8cMMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/register/new-forest-owner.vue"),
    children: [
  {
    name: "register-new-forest-owner-about-you",
    path: "about-you",
    component: () => import("/home/vsts/work/1/s/pages/register/new-forest-owner/about-you.vue")
  },
  {
    name: "register-new-forest-owner-allma",
    path: "allma",
    component: () => import("/home/vsts/work/1/s/pages/register/new-forest-owner/allma.vue")
  },
  {
    name: "register-new-forest-owner-bank-account",
    path: "bank-account",
    component: () => import("/home/vsts/work/1/s/pages/register/new-forest-owner/bank-account.vue")
  },
  {
    name: "register-new-forest-owner-company",
    path: "company",
    component: () => import("/home/vsts/work/1/s/pages/register/new-forest-owner/company.vue")
  },
  {
    name: "register-new-forest-owner-confirm-access",
    path: "confirm-access",
    component: () => import("/home/vsts/work/1/s/pages/register/new-forest-owner/confirm-access.vue")
  },
  {
    name: "register-new-forest-owner-contact-info",
    path: "contact-info",
    component: () => import("/home/vsts/work/1/s/pages/register/new-forest-owner/contact-info.vue")
  },
  {
    name: "register-new-forest-owner-done",
    path: "done",
    component: () => import("/home/vsts/work/1/s/pages/register/new-forest-owner/done.vue")
  },
  {
    name: "register-new-forest-owner",
    path: "",
    meta: indexf0fvQbINwUP9ssF7ZYsnGqJJW8uSLZ2P7jR6Tih5vYIMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/register/new-forest-owner/index.vue")
  },
  {
    name: "register-new-forest-owner-login",
    path: "login",
    component: () => import("/home/vsts/work/1/s/pages/register/new-forest-owner/login.vue")
  },
  {
    name: "register-new-forest-owner-share-holder",
    path: "share-holder",
    component: () => import("/home/vsts/work/1/s/pages/register/new-forest-owner/share-holder.vue")
  },
  {
    name: "register-new-forest-owner-skog-subscription",
    path: "skog-subscription",
    component: () => import("/home/vsts/work/1/s/pages/register/new-forest-owner/skog-subscription.vue")
  },
  {
    name: "register-new-forest-owner-summary",
    path: "summary",
    component: () => import("/home/vsts/work/1/s/pages/register/new-forest-owner/summary.vue")
  },
  {
    name: "register-new-forest-owner-transfer",
    path: "transfer",
    component: () => import("/home/vsts/work/1/s/pages/register/new-forest-owner/transfer.vue")
  }
]
  },
  {
    name: _91contactId_93i1hVDq9AuvIvAQ7q8nhyFttxOdkIQdI6AmNCP1WpCO0Meta?.name,
    path: "/register/simple/:contactId()",
    meta: _91contactId_93i1hVDq9AuvIvAQ7q8nhyFttxOdkIQdI6AmNCP1WpCO0Meta || {},
    component: () => import("/home/vsts/work/1/s/pages/register/simple/[contactId].vue"),
    children: [
  {
    name: "register-simple-contactId-completing",
    path: "completing",
    component: () => import("/home/vsts/work/1/s/pages/register/simple/[contactId]/completing.vue")
  },
  {
    name: "register-simple-contactId-confirm-access",
    path: "confirm-access",
    component: () => import("/home/vsts/work/1/s/pages/register/simple/[contactId]/confirm-access.vue")
  },
  {
    name: "register-simple-contactId-contact-info",
    path: "contact-info",
    component: () => import("/home/vsts/work/1/s/pages/register/simple/[contactId]/contact-info.vue")
  },
  {
    name: "register-simple-contactId",
    path: "",
    meta: indexmI4QJRrxbRE2CHhkj3NUDa3kCNZ6xzrs2vMnzSAA5NEMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/register/simple/[contactId]/index.vue")
  },
  {
    name: "register-simple-contactId-login",
    path: "login",
    component: () => import("/home/vsts/work/1/s/pages/register/simple/[contactId]/login.vue")
  },
  {
    name: "register-simple-contactId-success",
    path: "success",
    component: () => import("/home/vsts/work/1/s/pages/register/simple/[contactId]/success.vue")
  },
  {
    name: "register-simple-contactId-summary",
    path: "summary",
    component: () => import("/home/vsts/work/1/s/pages/register/simple/[contactId]/summary.vue")
  }
]
  },
  {
    name: "resetpassword-id",
    path: "/resetpassword/:id()",
    meta: _91id_93qhB3fc7YM2REDLaauYiD6_pQ_xGqtMrkCwxmnSjRag4Meta || {},
    component: () => import("/home/vsts/work/1/s/pages/resetpassword/[id].vue")
  }
]