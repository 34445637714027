import routerOptions0 from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.4.1_@types+node@20.14.12_db0@0.3.1_eslint@9.22.0_jiti@2.4_323b3fb3c4d8f5dc2a6c7223de5dbeee/node_modules/nuxt/dist/pages/runtime/router.options.js";
const configRouterOptions = {
  linkActiveClass: "menu__item--active",
  linkExactActiveClass: "menu__item--active--exact",
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = false
export default {
...configRouterOptions,
...routerOptions0,
}