import payload_plugin_Y_BP6WJ06LXRRZ8KtecHjlJmr_gqw7vds7MfRVhn3Uk from "/home/vsts/work/1/s/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2___rollup@4.36.0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_M1ntHiHW5c8ZpI2nR_H1EjzRwJFeqneeMbiGMwPnxxo from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.4.1_@types+node@20.14.12_db0@0.3.1_eslint@9.22.0_jiti@2.4_323b3fb3c4d8f5dc2a6c7223de5dbeee/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_36E7LOQ9BUr3SQpyTB2gFmCaWLnfU_73_HEO8tc5p60 from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.4.1_@types+node@20.14.12_db0@0.3.1_eslint@9.22.0_jiti@2.4_323b3fb3c4d8f5dc2a6c7223de5dbeee/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_FegeRCQXRs8_QkGTK_w_4LTrEkNhh40hUc74at5JEuY from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.4.1_@types+node@20.14.12_db0@0.3.1_eslint@9.22.0_jiti@2.4_323b3fb3c4d8f5dc2a6c7223de5dbeee/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_EKUozx791Us_h_Rdpo9Fn__6YEzLFW1MCK0uF2yWvMM from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.4.1_@types+node@20.14.12_db0@0.3.1_eslint@9.22.0_jiti@2.4_323b3fb3c4d8f5dc2a6c7223de5dbeee/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_jOssHTg9G9DwVNHssPcENfWpPxuiUcT1nj1_6di9CVQ from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.4.1_@types+node@20.14.12_db0@0.3.1_eslint@9.22.0_jiti@2.4_323b3fb3c4d8f5dc2a6c7223de5dbeee/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_g60eVMukcz8Mzqb7mfJvPmEmC4hx4cnxsn_wGAG7WxM from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.4.1_@types+node@20.14.12_db0@0.3.1_eslint@9.22.0_jiti@2.4_323b3fb3c4d8f5dc2a6c7223de5dbeee/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_DQ89RVeXPWLXL2y140cY39GQlvWMgzNSi3CZlPs2l5w from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.4.1_@types+node@20.14.12_db0@0.3.1_eslint@9.22.0_jiti@2.4_323b3fb3c4d8f5dc2a6c7223de5dbeee/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_FmMfYvGJZoDDT_XEdDrzfygbsZEiXEpnSRbWxzBH3tI from "/home/vsts/work/1/s/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2___rollup@4.36.0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/home/vsts/work/1/s/.nuxt/components.plugin.mjs";
import prefetch_client_IlZSigpg1oH1VQGNZTDHEkuy_V6zhwc6IiyJ__L3L0Y from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.4.1_@types+node@20.14.12_db0@0.3.1_eslint@9.22.0_jiti@2.4_323b3fb3c4d8f5dc2a6c7223de5dbeee/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import floating_vue_0VJpPLm37OmsRfOoVrjUztB_kqo8e0M_PqepnJl50BQ from "/home/vsts/work/1/s/.nuxt/floating-vue.mjs";
import switch_locale_path_ssr_O_SZFT8WRERk674H_OcjUPS9TD5IiAJONIB8_tBkHXU from "/home/vsts/work/1/s/node_modules/.pnpm/@nuxtjs+i18n@9.3.2_@vue+compiler-dom@3.5.13_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_ro_a2c5ef04b64d4ca8d261fc10a7b0a421/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_Ox2lyeWUY0r3k1cWr3jnqhxhJJYV6SWVPl2UDFAbTNk from "/home/vsts/work/1/s/node_modules/.pnpm/@nuxtjs+i18n@9.3.2_@vue+compiler-dom@3.5.13_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_ro_a2c5ef04b64d4ca8d261fc10a7b0a421/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_gY9hYSswlI8ZDNYkk2R5Y5MKLD8jQvMLaWGQmoON_YI from "/home/vsts/work/1/s/node_modules/.pnpm/@nuxtjs+i18n@9.3.2_@vue+compiler-dom@3.5.13_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_ro_a2c5ef04b64d4ca8d261fc10a7b0a421/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import _1_application_insights_client_tL4YJt6VVoQtjS4Kaevat1qU_c90Te2mCYcoAS0kekM from "/home/vsts/work/1/s/plugins/1.application-insights.client.ts";
import _2_axios__CxRkU2_sPE2Ty6DRlgCuIVXXnY0QwExmj4mttqi9YQ from "/home/vsts/work/1/s/plugins/2.axios.ts";
import _3_bank_id_axios_JMjdexuhcxbrIOzpxBQudcB4qAdMpxvZeXX9s4Ahang from "/home/vsts/work/1/s/plugins/3.bank-id-axios.ts";
import componentUUID__QsDXCkNRFsVibvMteFcpoaMTBEj_x6JymINLi79wmU from "/home/vsts/work/1/s/plugins/componentUUID.ts";
import highlight_client_s9aXFGt0OS5xAJlr_6UyBfJgENlX2xNpUsa1D8qo3PQ from "/home/vsts/work/1/s/plugins/highlight.client.ts";
import i18n_client_jL_UBQpzQbmGMFa3q65k3taMArgaSHLdwI5SIZQH6N0 from "/home/vsts/work/1/s/plugins/i18n.client.ts";
import nuxt_confirm_client_guTXfdw9s7nwCN5we64nVKzhnVjVkd_1AZ2pAmtNsMk from "/home/vsts/work/1/s/plugins/nuxt-confirm.client.ts";
import nuxt_link_import_OPgHYIKvqx5v3udHRqMHT8iNYJ76pAMyxr64FQ6pdgU from "/home/vsts/work/1/s/plugins/nuxt-link-import.ts";
import router_watch_njbOXVQriST00HTV_3ta_KYuf_d_EeIAtI0pn1tRJUY from "/home/vsts/work/1/s/plugins/router-watch.ts";
import sentry_logging_2hQRb03gAtoEIqAcb6pKNXkJNnnGZwm88zGTpsUVueM from "/home/vsts/work/1/s/plugins/sentry-logging.ts";
import vue_mask_WHaln3xmbio_fX_idmNGZ2nORY8w3liItQTHOLYzOEI from "/home/vsts/work/1/s/plugins/vue-mask.ts";
import vue_query_GNtWx11mSO94O_Ctd2OG8SaawZMBzi1ne17Edj_iIOc from "/home/vsts/work/1/s/plugins/vue-query.ts";
import vue_scrollto_KQPP2_0zOSPejmsbK_e2OVhalW7ZrYZrsN6VauhbkyQ from "/home/vsts/work/1/s/plugins/vue-scrollto.ts";
import vue_upload_MF7fWZvMSR8EwWJmX8G5kPSjsReqtGQqQSoizSKG5mk from "/home/vsts/work/1/s/plugins/vue-upload.ts";
import ssg_detect_dQjqTMlfNumYkvkTFRD06Vbls45Ik188Kui_tdPApQY from "/home/vsts/work/1/s/node_modules/.pnpm/@nuxtjs+i18n@9.3.2_@vue+compiler-dom@3.5.13_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_ro_a2c5ef04b64d4ca8d261fc10a7b0a421/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_Y_BP6WJ06LXRRZ8KtecHjlJmr_gqw7vds7MfRVhn3Uk,
  revive_payload_client_M1ntHiHW5c8ZpI2nR_H1EjzRwJFeqneeMbiGMwPnxxo,
  unhead_36E7LOQ9BUr3SQpyTB2gFmCaWLnfU_73_HEO8tc5p60,
  router_FegeRCQXRs8_QkGTK_w_4LTrEkNhh40hUc74at5JEuY,
  payload_client_EKUozx791Us_h_Rdpo9Fn__6YEzLFW1MCK0uF2yWvMM,
  navigation_repaint_client_jOssHTg9G9DwVNHssPcENfWpPxuiUcT1nj1_6di9CVQ,
  check_outdated_build_client_g60eVMukcz8Mzqb7mfJvPmEmC4hx4cnxsn_wGAG7WxM,
  chunk_reload_client_DQ89RVeXPWLXL2y140cY39GQlvWMgzNSi3CZlPs2l5w,
  plugin_vue3_FmMfYvGJZoDDT_XEdDrzfygbsZEiXEpnSRbWxzBH3tI,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_IlZSigpg1oH1VQGNZTDHEkuy_V6zhwc6IiyJ__L3L0Y,
  floating_vue_0VJpPLm37OmsRfOoVrjUztB_kqo8e0M_PqepnJl50BQ,
  switch_locale_path_ssr_O_SZFT8WRERk674H_OcjUPS9TD5IiAJONIB8_tBkHXU,
  route_locale_detect_Ox2lyeWUY0r3k1cWr3jnqhxhJJYV6SWVPl2UDFAbTNk,
  i18n_gY9hYSswlI8ZDNYkk2R5Y5MKLD8jQvMLaWGQmoON_YI,
  _1_application_insights_client_tL4YJt6VVoQtjS4Kaevat1qU_c90Te2mCYcoAS0kekM,
  _2_axios__CxRkU2_sPE2Ty6DRlgCuIVXXnY0QwExmj4mttqi9YQ,
  _3_bank_id_axios_JMjdexuhcxbrIOzpxBQudcB4qAdMpxvZeXX9s4Ahang,
  componentUUID__QsDXCkNRFsVibvMteFcpoaMTBEj_x6JymINLi79wmU,
  highlight_client_s9aXFGt0OS5xAJlr_6UyBfJgENlX2xNpUsa1D8qo3PQ,
  i18n_client_jL_UBQpzQbmGMFa3q65k3taMArgaSHLdwI5SIZQH6N0,
  nuxt_confirm_client_guTXfdw9s7nwCN5we64nVKzhnVjVkd_1AZ2pAmtNsMk,
  nuxt_link_import_OPgHYIKvqx5v3udHRqMHT8iNYJ76pAMyxr64FQ6pdgU,
  router_watch_njbOXVQriST00HTV_3ta_KYuf_d_EeIAtI0pn1tRJUY,
  sentry_logging_2hQRb03gAtoEIqAcb6pKNXkJNnnGZwm88zGTpsUVueM,
  vue_mask_WHaln3xmbio_fX_idmNGZ2nORY8w3liItQTHOLYzOEI,
  vue_query_GNtWx11mSO94O_Ctd2OG8SaawZMBzi1ne17Edj_iIOc,
  vue_scrollto_KQPP2_0zOSPejmsbK_e2OVhalW7ZrYZrsN6VauhbkyQ,
  vue_upload_MF7fWZvMSR8EwWJmX8G5kPSjsReqtGQqQSoizSKG5mk,
  ssg_detect_dQjqTMlfNumYkvkTFRD06Vbls45Ik188Kui_tdPApQY
]