<template>
  <div class="w-full">	
    <main id="main" class="constrained">
			<breadcrumbs />

      <PropertyStatusForm
          :is-loading="isLoading"
          :property-id="myProperty?.Property?.PropertyIdCrm"
          :property-name="myProperty?.Property?.Name"
          :owner-comment-status="myProperty?.Property?.OwnerCommentStatus"
          :owner-comment-status-specified="myProperty?.Property?.OwnerCommentStatusSpecified"
          :account-id="myProperty?.PropertyOwnerAccountCrm?.AccountIdCrm"
          >
          
          </PropertyStatusForm>
    
		</main>
	</div>
</template>

<script lang="ts">
import Button from '~/components/buttons/Button.vue'
import PropertyStatusForm from '~/components/pages/my-account/my-property/PropertyStatusForm.vue'
import {useDelegationOfAuthorityStore} from "~/stores/delegationOfAuthority"
import {toRaw} from 'vue'

definePageMeta({
  middleware: defineNuxtRouteMiddleware(() => {
    const $config = useRuntimeConfig()
    if ($config.public.features.delegateRightOfUse === false) {
      return navigateTo(`/my-account/user-access`, {
        replace: true,
      })
    }
  })
})

export default defineComponent({
		components: {
			Button,
      PropertyStatusForm
	  },
    computed: {
      myProperty() {
        var item = this.delegationOfAuthorityStore.ownedPropertiesContact.find((item) => item.Property?.PropertyIdCrm == this.propertyId)    
        return toRaw(item)
      },
    },
    setup(){
      const isLoading = ref(true)
      const delegationOfAuthorityStore = useDelegationOfAuthorityStore()
      
      const fetchProperties = async () => {
			await delegationOfAuthorityStore.loadOwnedPropertiesContact()
			isLoading.value = false
			
		}
    onMounted(() => fetchProperties())

		const route = useRoute()
    const propertyId = route.params.propertyId
    
    return {
      propertyId, 
      delegationOfAuthorityStore,
			isLoading
		}
  }
})
</script>
